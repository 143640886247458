import { useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import { SessionService } from '../services/auth/session.service';
import { AuditService } from '../services/audit/audit.service';
import { toast } from 'react-hot-toast';

const ACTIVITY_EVENTS = ['mousedown', 'keydown', 'scroll', 'touchstart', 'mousemove'];
const WARNING_THRESHOLD = 25 * 60 * 1000; // 25 minutes
const CHECK_INTERVAL = 60 * 1000; // 1 minute
const ACTIVITY_THROTTLE = 1000; // 1 second

export const useSessionMonitor = () => {
  const { signOut } = useAuth();
  const warningShown = useRef(false);
  const warningTimerId = useRef<NodeJS.Timeout>();
  const lastActivityUpdate = useRef<number>(Date.now());

  useEffect(() => {
    console.log('[SessionMonitor] Initializing');

    const handleActivity = () => {
      const now = Date.now();
      // Throttle activity updates
      if (now - lastActivityUpdate.current < ACTIVITY_THROTTLE) {
        return;
      }
      
      lastActivityUpdate.current = now;
      SessionService.updateLastActivity();
      
      // Clear warning if it was shown
      if (warningShown.current) {
        warningShown.current = false;
        if (warningTimerId.current) {
          clearTimeout(warningTimerId.current);
          warningTimerId.current = undefined;
        }
      }
    };

    const checkSession = async () => {
      try {
        const sessionInfo = SessionService.getSessionInfo();
        if (!sessionInfo.lastActivity) return;

        const timeSinceActivity = Date.now() - sessionInfo.lastActivity;

        // Show warning when approaching timeout
        if (timeSinceActivity >= WARNING_THRESHOLD && !warningShown.current) {
          warningShown.current = true;
          
          toast.warning('Your session will expire soon. Please take action to stay logged in.', {
            duration: 10000,
            id: 'session-warning'
          });

          await AuditService.logEvent('session', 'expiry_warning', {
            timeSinceActivity,
            sessionInfo
          });

          // Set timer for actual logout
          warningTimerId.current = setTimeout(async () => {
            await AuditService.logEvent('session', 'expired', {
              sessionInfo
            });
            signOut();
          }, SessionService.SESSION_TIMEOUT - WARNING_THRESHOLD);
        }

        // Log session check
        await AuditService.logEvent('session', 'check_completed', {
          timeSinceActivity,
          isValid: SessionService.isSessionValid()
        });
      } catch (error) {
        console.error('Session check error:', error);
        await AuditService.logEvent('session', 'check_error', {
          error: error instanceof Error ? error.message : 'Unknown error'
        });
      }
    };

    // Set up activity listeners with throttling
    let activityTimeout: NodeJS.Timeout;
    const throttledActivity = () => {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(handleActivity, ACTIVITY_THROTTLE);
    };

    ACTIVITY_EVENTS.forEach(event => {
      window.addEventListener(event, throttledActivity, { passive: true });
    });

    // Set up session check interval
    const intervalId = setInterval(checkSession, CHECK_INTERVAL);

    // Initial activity update
    handleActivity();

    return () => {
      console.log('[SessionMonitor] Cleaning up');
      ACTIVITY_EVENTS.forEach(event => {
        window.removeEventListener(event, throttledActivity);
      });
      clearInterval(intervalId);
      clearTimeout(activityTimeout);
      if (warningTimerId.current) {
        clearTimeout(warningTimerId.current);
      }
    };
  }, [signOut]);
};