import { FirebaseSettingsService } from './firebase/settings.service';
import { getAuth } from 'firebase/auth';
import { AuditService } from './audit/audit.service';
import { SecureStorageWrapper } from './security/secure-storage.wrapper';

export interface WordPressSettings {
  baseUrl: string;
  username: string;
  password: string;
  seoPlugin: 'rank-math' | 'yoast' | 'aioseo' | 'none';
  siteName?: string;
  location?: string;
  industry?: string;
}

export interface SettingsState {
  wordpress?: WordPressSettings;
  openai?: {
    apiKey: string;
    organization?: string;
    model: 'gpt-4' | 'gpt-3.5-turbo' | 'gpt-4-mini';
    maxTokens: number;
    temperature: number;
    usage?: number;
  };
  deepseek?: {
    apiKey: string;
    monthlyBudget?: number;
  };
  // Add the provider preference field
  llmProvider?: 'openai' | 'deepseek';
}

export class SettingsService {
  private readonly firebaseSettings: FirebaseSettingsService;
  private readonly secureStorage: SecureStorageWrapper;

  constructor() {
    this.firebaseSettings = FirebaseSettingsService.getInstance();
    this.secureStorage = SecureStorageWrapper.getInstance();
  }

  async getSettings(): Promise<SettingsState | null> {
    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        console.warn('No authenticated user found when getting settings');
        return null;
      }

      const settings = await this.firebaseSettings.getSettings(userId);
      
      // If we have settings in Firebase but no LLM provider set, try to determine one
      if (settings && !settings.llmProvider) {
        if (settings.openai?.apiKey) {
          settings.llmProvider = 'openai';
        } else if (settings.deepseek?.apiKey) {
          settings.llmProvider = 'deepseek';
        }
      }

      await AuditService.logEvent('settings', 'settings_retrieved', {
        userId,
        hasSettings: !!settings,
        provider: settings?.llmProvider || 'not-set'
      });

      return settings;
    } catch (error) {
      console.error('Failed to get settings:', error);
      await AuditService.logEvent('settings', 'settings_retrieval_failed', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      return null;
    }
  }

  async saveSettings(settings: SettingsState): Promise<void> {
    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        throw new Error('No authenticated user found when saving settings');
      }

      // Get current settings to preserve other provider's config
      const currentSettings = await this.getSettings() || {};
      
      // Determine the LLM provider if not explicitly set
      if (!settings.llmProvider && !currentSettings.llmProvider) {
        // If OpenAI settings are being updated, set provider to OpenAI
        if (settings.openai?.apiKey) {
          settings.llmProvider = 'openai';
        }
        // If DeepSeek settings are being updated, set provider to DeepSeek
        else if (settings.deepseek?.apiKey) {
          settings.llmProvider = 'deepseek';
        }
        // Otherwise keep any existing provider preference
        else if (currentSettings.llmProvider) {
          settings.llmProvider = currentSettings.llmProvider;
        }
      }
      
      // Merge settings, preserving fields not included in the update
      const mergedSettings = {
        ...currentSettings,
        ...settings,
        // Ensure the provider choice is preserved
        llmProvider: settings.llmProvider || currentSettings.llmProvider
      };
      
      // Save to Firebase
      await this.firebaseSettings.saveSettings(userId, mergedSettings);

      // Update local storage with merged settings
      await this.secureStorage.setItem('autoboost_settings', mergedSettings);
      
      // Also update localStorage provider for compatibility with existing code
      if (mergedSettings.llmProvider) {
        localStorage.setItem('llm_provider', mergedSettings.llmProvider);
        
        // Also update the respective provider config in localStorage
        if (mergedSettings.llmProvider === 'openai' && mergedSettings.openai) {
          localStorage.setItem('llm_config', JSON.stringify(mergedSettings.openai));
        } else if (mergedSettings.llmProvider === 'deepseek' && mergedSettings.deepseek) {
          localStorage.setItem('deepseek_config', JSON.stringify(mergedSettings.deepseek));
        }
      }

      await AuditService.logEvent('settings', 'settings_saved', {
        userId,
        provider: mergedSettings.llmProvider || 'not-set'
      });
    } catch (error) {
      console.error('Failed to save settings:', error);
      await AuditService.logEvent('settings', 'settings_save_failed', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  }

  async clearSettings(): Promise<void> {
    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;
      
      if (!userId) {
        throw new Error('No authenticated user found when clearing settings');
      }
      
      await this.firebaseSettings.clearSettings(userId);
      
      // Clear all settings from local storage
      await this.secureStorage.removeItem('autoboost_settings');
      localStorage.removeItem('llm_provider');
      localStorage.removeItem('llm_config');
      localStorage.removeItem('deepseek_config');
      localStorage.removeItem('api_usage');
      localStorage.removeItem('template_overrides');
      localStorage.removeItem('custom_templates');
      
      await AuditService.logEvent('settings', 'settings_cleared', {
        userId
      });
    } catch (error) {
      console.error('Failed to clear settings:', error);
      await AuditService.logEvent('settings', 'settings_clear_failed', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  }
}
