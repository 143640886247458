import CryptoJS from 'crypto-js';
import { AuditService } from '../audit/audit.service';

export class SecureStorageService {
  private static instance: SecureStorageService;
  private readonly encryptionKey: string;
  private readonly prefix: string = 'secure_';
  private readonly fallbackStorage: Map<string, string> = new Map();

  private constructor() {
    const key = import.meta.env.VITE_ENCRYPTION_KEY;
    if (!key) {
      throw new Error('Encryption key not configured');
    }
    this.encryptionKey = key;
  }

  static getInstance(): SecureStorageService {
    if (!SecureStorageService.instance) {
      SecureStorageService.instance = new SecureStorageService();
    }
    return SecureStorageService.instance;
  }

  async setItem(key: string, value: string): Promise<void> {
    try {
      const encryptedValue = CryptoJS.AES.encrypt(value, this.encryptionKey).toString();
      const fullKey = this.prefix + key;
      
      try {
        localStorage.setItem(fullKey, encryptedValue);
      } catch (storageError) {
        console.warn('localStorage failed, using fallback storage:', storageError);
        this.fallbackStorage.set(fullKey, encryptedValue);
      }

      await AuditService.logEvent('storage', 'item_stored', {
        key: fullKey
      });
    } catch (error) {
      console.error('Storage error:', error);
      await AuditService.logEvent('storage', 'store_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw new Error('Failed to store data securely');
    }
  }

  async getItem(key: string): Promise<string | null> {
    try {
      const fullKey = this.prefix + key;
      let encryptedValue: string | null = null;

      try {
        encryptedValue = localStorage.getItem(fullKey);
      } catch (storageError) {
        console.warn('localStorage failed, using fallback storage:', storageError);
        encryptedValue = this.fallbackStorage.get(fullKey) || null;
      }

      if (!encryptedValue) return null;
      
      const bytes = CryptoJS.AES.decrypt(encryptedValue, this.encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Retrieval error:', error);
      await AuditService.logEvent('storage', 'retrieval_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      return null;
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      const fullKey = this.prefix + key;
      localStorage.removeItem(fullKey);
      this.fallbackStorage.delete(fullKey);

      await AuditService.logEvent('storage', 'item_removed', {
        key: fullKey
      });
    } catch (error) {
      console.error('Remove error:', error);
      throw error;
    }
  }

  async clear(): Promise<void> {
    try {
      // Clear all items with our prefix
      Object.keys(localStorage)
        .filter(key => key.startsWith(this.prefix))
        .forEach(key => localStorage.removeItem(key));

      // Clear fallback storage
      this.fallbackStorage.clear();

      await AuditService.logEvent('storage', 'storage_cleared');
    } catch (error) {
      console.error('Clear error:', error);
      throw error;
    }
  }
}