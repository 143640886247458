import { SecureStorageService } from './secure-storage.service';
import { ObfuscationService } from './obfuscation.service';
import { ObfuscationValidator } from './validation/obfuscation-validator';

export class SecureStorageWrapper {
  private static instance: SecureStorageWrapper;
  private secureStorage: SecureStorageService;
  private obfuscationService: ObfuscationService;

  private constructor() {
    this.secureStorage = SecureStorageService.getInstance();
    this.obfuscationService = ObfuscationService.getInstance();
  }

  static getInstance(): SecureStorageWrapper {
    if (!SecureStorageWrapper.instance) {
      SecureStorageWrapper.instance = new SecureStorageWrapper();
    }
    return SecureStorageWrapper.instance;
  }

  async setItem(key: string, value: any): Promise<void> {
    try {
      // Obfuscate both key and value
      const obfuscatedKey = this.obfuscationService.obfuscateString(key);
      const serializedValue = JSON.stringify(value);
      const obfuscatedValue = this.obfuscationService.obfuscateString(serializedValue);

      // Validate obfuscation
      if (!ObfuscationValidator.validateObfuscatedString(obfuscatedValue)) {
        throw new Error('Obfuscation validation failed');
      }

      await this.secureStorage.setItem(obfuscatedKey, obfuscatedValue);
    } catch (error) {
      console.error('Secure storage set error:', error);
      throw new Error('Failed to store data securely');
    }
  }

  async getItem<T>(key: string): Promise<T | null> {
    try {
      const obfuscatedKey = this.obfuscationService.obfuscateString(key);
      const obfuscatedValue = await this.secureStorage.getItem(obfuscatedKey);
      
      if (!obfuscatedValue) return null;

      // Validate before deobfuscating
      if (!ObfuscationValidator.validateObfuscatedString(obfuscatedValue)) {
        throw new Error('Invalid obfuscated data detected');
      }

      const deobfuscatedValue = this.obfuscationService.deobfuscateString(obfuscatedValue);
      return JSON.parse(deobfuscatedValue);
    } catch (error) {
      console.error('Secure storage get error:', error);
      return null;
    }
  }

  async removeItem(key: string): Promise<void> {
    const obfuscatedKey = this.obfuscationService.obfuscateString(key);
    await this.secureStorage.removeItem(obfuscatedKey);
  }

  async clear(): Promise<void> {
    await this.secureStorage.clear();
  }
}