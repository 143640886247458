import { StringObfuscator } from './crypto/string-obfuscator';
import { ObjectObfuscator } from './crypto/object-obfuscator';
import { FunctionObfuscator } from './crypto/function-obfuscator';

export class ObfuscationService {
  private static readonly KEY = import.meta.env.VITE_ENCRYPTION_KEY;
  private static instance: ObfuscationService;
  
  private stringObfuscator: StringObfuscator;
  private objectObfuscator: ObjectObfuscator;
  private functionObfuscator: FunctionObfuscator;

  private constructor() {
    if (!ObfuscationService.KEY) {
      throw new Error('Encryption key not configured');
    }

    this.stringObfuscator = new StringObfuscator(ObfuscationService.KEY);
    this.objectObfuscator = new ObjectObfuscator(ObfuscationService.KEY);
    this.functionObfuscator = new FunctionObfuscator(ObfuscationService.KEY);
  }

  static getInstance(): ObfuscationService {
    if (!ObfuscationService.instance) {
      ObfuscationService.instance = new ObfuscationService();
    }
    return ObfuscationService.instance;
  }

  obfuscateString(value: string): string {
    return this.stringObfuscator.obfuscate(value);
  }

  deobfuscateString(value: string): string {
    return this.stringObfuscator.deobfuscate(value);
  }

  obfuscateObject<T extends object>(obj: T): T {
    return this.objectObfuscator.obfuscate(obj);
  }

  deobfuscateObject<T extends object>(obj: T): T {
    return this.objectObfuscator.deobfuscate(obj);
  }

  obfuscateFunction(fn: Function): string {
    return this.functionObfuscator.obfuscate(fn);
  }

  deobfuscateFunction(obfuscatedFn: string): Function {
    return this.functionObfuscator.deobfuscate(obfuscatedFn);
  }
}