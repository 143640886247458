import React from 'react';
import { Plus, Minus } from 'lucide-react';

export const FAQ = () => {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const faqs = [
    {
      question: "How does AutoBoost enhance my content?",
      answer: "AutoBoost uses advanced AI algorithms to analyze and enhance your content across multiple dimensions including readability, SEO optimization, and engagement. It maintains your brand voice while improving the quality and reach of your content."
    },
    {
      question: "Is AutoBoost compatible with my WordPress setup?",
      answer: "Yes! AutoBoost is designed to work seamlessly with WordPress and popular SEO plugins like Yoast SEO, RankMath, and All in One SEO. Our integration process is straightforward and requires minimal technical knowledge."
    },
    {
      question: "Can I customize the enhancement templates?",
      answer: "Absolutely! While we provide pre-built templates optimized for different content types, you can fully customize these templates or create your own to match your specific needs and content strategy."
    },
    {
      question: "How secure is my content data?",
      answer: "We take security seriously. All data is encrypted in transit and at rest, and we never store your WordPress credentials. We use industry-standard security practices and regular security audits to protect your content."
    },
    {
      question: "What kind of support do you offer?",
      answer: "We provide comprehensive support including email support, documentation, video tutorials, and a knowledge base. Professional and Enterprise plans include priority support and dedicated account management."
    }
  ];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq, index) => (
              <div key={index} className="pt-6">
                <dt>
                  <button
                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                    className="flex w-full items-start justify-between text-left text-gray-900"
                  >
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      {openIndex === index ? (
                        <Minus className="h-6 w-6" />
                      ) : (
                        <Plus className="h-6 w-6" />
                      )}
                    </span>
                  </button>
                </dt>
                {openIndex === index && (
                  <dd className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};