import React from 'react';
import { Settings, FileText, Wand2 } from 'lucide-react';
import { TopNav } from './navigation/TopNav';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  const menuItems = [
    { icon: FileText, label: 'Content', href: '/content' },
    { icon: Wand2, label: 'Templates', href: '/templates' },
    { icon: Settings, label: 'Settings', href: '/settings' },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNav menuItems={menuItems} />
      <main className="container mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <Outlet />
      </main>
    </div>
  );
};