import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Wand2, Sparkles, BarChart2, ArrowRight } from 'lucide-react';

export const Hero = () => {
  const navigate = useNavigate();

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative isolate">
      {/* Background gradient */}
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
      </div>

      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <a href="#features" className="inline-flex space-x-6">
              <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-500/20">
                What's new
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                <span>Just shipped v1.0</span>
                <ArrowRight className="h-4 w-4" />
              </span>
            </a>
          </div>
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Enhance Your WordPress Content with AI
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Automatically optimize your WordPress content for better engagement, readability, and SEO performance using advanced AI technology.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              onClick={() => navigate('/signin')}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </button>
            <button
              onClick={() => scrollToSection('features')}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </button>
          </div>
        </div>

        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div className="rounded-2xl bg-gray-50/80 p-8 ring-1 ring-inset ring-gray-200">
                <Wand2 className="h-8 w-8 text-indigo-600" />
                <h3 className="mt-4 text-lg font-semibold text-gray-900">AI Enhancement</h3>
                <p className="mt-2 text-sm text-gray-600">
                  Automatically enhance your content using advanced AI algorithms
                </p>
              </div>
              <div className="rounded-2xl bg-gray-50/80 p-8 ring-1 ring-inset ring-gray-200">
                <Sparkles className="h-8 w-8 text-indigo-600" />
                <h3 className="mt-4 text-lg font-semibold text-gray-900">SEO Optimization</h3>
                <p className="mt-2 text-sm text-gray-600">
                  Improve your search rankings with AI-powered SEO suggestions
                </p>
              </div>
              <div className="rounded-2xl bg-gray-50/80 p-8 ring-1 ring-inset ring-gray-200">
                <BarChart2 className="h-8 w-8 text-indigo-600" />
                <h3 className="mt-4 text-lg font-semibold text-gray-900">Analytics</h3>
                <p className="mt-2 text-sm text-gray-600">
                  Track your content performance and improvements
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};