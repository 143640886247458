import { StringObfuscator } from './string-obfuscator';

export class ObjectObfuscator {
  private stringObfuscator: StringObfuscator;

  constructor(key: string) {
    this.stringObfuscator = new StringObfuscator(key);
  }

  obfuscate<T extends object>(obj: T): T {
    const obfuscated: any = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string') {
        obfuscated[key] = this.stringObfuscator.obfuscate(value);
      } else if (typeof value === 'object' && value !== null) {
        obfuscated[key] = this.obfuscate(value);
      } else {
        obfuscated[key] = value;
      }
    }

    return obfuscated as T;
  }

  deobfuscate<T extends object>(obj: T): T {
    const deobfuscated: any = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string') {
        deobfuscated[key] = this.stringObfuscator.deobfuscate(value);
      } else if (typeof value === 'object' && value !== null) {
        deobfuscated[key] = this.deobfuscate(value);
      } else {
        deobfuscated[key] = value;
      }
    }

    return deobfuscated as T;
  }
}