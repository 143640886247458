import React from 'react';
import { 
  Sparkles, Target, BarChart2, RefreshCw, 
  Zap, FileText, Link2, Search 
} from 'lucide-react';

const features = [
  {
    name: 'AI-Powered Content Enhancement',
    description: 'Leverage advanced AI algorithms to automatically enhance your content for better engagement and readability.',
    icon: Sparkles,
  },
  {
    name: 'SEO Optimization',
    description: 'Get smart keyword suggestions and meta tag improvements to boost your search engine rankings.',
    icon: Target,
  },
  {
    name: 'Performance Analytics',
    description: 'Track your content performance with detailed analytics and actionable insights.',
    icon: BarChart2,
  },
  {
    name: 'Automated Updates',
    description: 'Keep your content fresh with automated updates and improvements.',
    icon: RefreshCw,
  },
  {
    name: 'Quick Integration',
    description: 'Easy setup with popular WordPress SEO plugins like Yoast, RankMath, and All in One SEO.',
    icon: Zap,
  },
  {
    name: 'Content Audit',
    description: 'Identify and fix content gaps and optimization opportunities across your site.',
    icon: FileText,
  },
  {
    name: 'Internal Linking',
    description: 'Get smart suggestions for internal linking to improve your site structure.',
    icon: Link2,
  },
  {
    name: 'Keyword Research',
    description: 'Find and track the right keywords to target for your content strategy.',
    icon: Search,
  }
];

export const Features = () => {
  return (
    <div className="bg-white py-24 sm:py-32" id="features">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Everything you need</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Powerful features for content enhancement
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Enhance your WordPress content with our comprehensive suite of AI-powered tools and features.
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="mt-16 sm:mt-24 text-center">
          <a
            href="#"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get started today
          </a>
        </div>
      </div>
    </div>
  );
};