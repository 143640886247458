import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore, memoryLocalCache, enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth, browserLocalPersistence, inMemoryPersistence, setPersistence } from 'firebase/auth';
import { AuditService } from '../audit/audit.service';

const firebaseConfig = {
  apiKey: "AIzaSyDsE9ePX9LjZeZ_-Gt4FTrFht5NUw_s-IY",
  authDomain: "boltautoboost.firebaseapp.com", 
  projectId: "boltautoboost",
  storageBucket: "boltautoboost.appspot.com",
  messagingSenderId: "334268964965",
  appId: "1:334268964965:web:c278126dda83420c9b268c"
};

console.log('[Firebase] Initializing Firebase app');

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

let db;

// Initialize Firestore based on environment
const isWebContainer = window.location.hostname.includes('webcontainer.io') || 
                      window.location.hostname.includes('stackblitz.io');

if (isWebContainer) {
  // Use memory-only cache in WebContainer
  db = initializeFirestore(app, {
    localCache: memoryLocalCache()
  });
} else {
  // Use IndexedDB persistence in production
  db = getFirestore(app);
  enableIndexedDbPersistence(db).catch((err) => {
    console.warn('Failed to enable persistence:', err);
  });
}

// Initialize Auth
const auth = getAuth(app);

// Set auth persistence based on environment
setPersistence(auth, isWebContainer ? inMemoryPersistence : browserLocalPersistence)
  .then(() => {
    console.log('[Firebase] Auth persistence configured');
  })
  .catch(error => {
    console.error('Failed to set auth persistence:', error);
  });

// Export initialized services
export { app, db, auth };