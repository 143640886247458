// src/utils/auth-error-messages.ts

export const getAuthErrorMessage = (errorCode: string): string => {
  const errorMessages: Record<string, string> = {
    // Firebase Authentication errors
    'auth/invalid-credential': 'The email or password you entered is incorrect.',
    'auth/user-not-found': 'No account exists with this email address.',
    'auth/wrong-password': 'The password you entered is incorrect.',
    'auth/email-already-in-use': 'This email address is already associated with an account.',
    'auth/weak-password': 'Please use a stronger password. It should be at least 6 characters.',
    'auth/invalid-email': 'Please enter a valid email address.',
    'auth/user-disabled': 'This account has been disabled. Please contact support.',
    'auth/too-many-requests': 'Too many unsuccessful attempts. Please wait a moment before trying again.',
    'auth/network-request-failed': 'Network error. Please check your internet connection and try again.',
    'auth/requires-recent-login': 'For security reasons, please sign in again to complete this action.',
    'auth/account-exists-with-different-credential': 'An account already exists with the same email but different sign-in method.',
    'auth/operation-not-allowed': 'This sign-in method is not allowed. Please contact support.',
    'auth/popup-closed-by-user': 'Sign-in window was closed before completing the process.',
    'auth/expired-action-code': 'This link has expired. Please request a new password reset email.',
    'auth/invalid-action-code': 'This link is invalid. It may have already been used or expired.',
    
    // Custom application errors
    'auth/password-mismatch': 'Passwords do not match. Please try again.',
    'auth/session-expired': 'Your session has expired. Please sign in again.',
    'auth/unauthorized': 'You do not have permission to access this resource.',
    'auth/account-not-verified': 'Please verify your email address before signing in.',
    
    // Generic fallback error
    'default': 'An error occurred during authentication. Please try again.'
  };

  return errorMessages[errorCode] || errorMessages['default'];
};

// Helper function to extract Firebase error code from error messages
export const extractErrorCode = (error: any): string => {
  if (error?.code) {
    return error.code;
  }
  
  // Extract code pattern like (auth/invalid-credential) from message string
  if (typeof error?.message === 'string') {
    const match = error.message.match(/\(([^)]+)\)/);
    if (match && match[1]) {
      return match[1];
    }
  }
  
  return 'default';
};
