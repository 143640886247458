import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SecurityProvider } from './services/security/security-context';
import { AuthProvider } from './context/AuthContext';
import { AppRoutes } from './routes';
import NProgress from 'nprogress';
import './services/firebase/firebase.init';
import './styles/nprogress.css';

// Configure NProgress
NProgress.configure({ 
  showSpinner: false,
  minimum: 0.3,
  speed: 500,
  trickleSpeed: 200
});

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: (error) => {
        console.error('[Query] Error:', error);
      }
    },
    mutations: {
      retry: 2,
      onError: (error) => {
        console.error('[Mutation] Error:', error);
      }
    }
  }
});

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <SecurityProvider>
              <AppRoutes />
              <Toaster 
                position="top-right"
                toastOptions={{
                  duration: 3000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                  success: {
                    duration: 3000,
                    icon: '✅'
                  },
                  error: {
                    duration: 5000,
                    icon: '❌'
                  },
                  loading: {
                    duration: 10000,
                    icon: '⏳'
                  }
                }}
              />
            </SecurityProvider>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;