import CryptoJS from 'crypto-js';

export class StringObfuscator {
  private readonly key: string;

  constructor(key: string) {
    this.key = key;
  }

  obfuscate(value: string): string {
    try {
      const chunks = value.match(/.{1,10}/g) || [];
      const encryptedChunks = chunks.map(chunk => 
        CryptoJS.AES.encrypt(chunk, this.key).toString()
      );
      return btoa(encryptedChunks.join('|'));
    } catch (error) {
      console.error('String obfuscation error:', error);
      return value;
    }
  }

  deobfuscate(value: string): string {
    try {
      const encryptedChunks = atob(value).split('|');
      const decryptedChunks = encryptedChunks.map(chunk => {
        const decrypted = CryptoJS.AES.decrypt(chunk, this.key);
        return decrypted.toString(CryptoJS.enc.Utf8);
      });
      return decryptedChunks.join('');
    } catch (error) {
      console.error('String deobfuscation error:', error);
      return value;
    }
  }
}