import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { registerServiceWorker } from './serviceWorkerRegistration';
import NProgress from 'nprogress';

// Enable all console output
if (import.meta.env.DEV) {
  window.console = new Proxy(window.console, {
    get: (target, prop) => {
      const value = target[prop];
      if (typeof value === 'function') {
        return (...args: any[]) => {
          value.apply(target, args);
        };
      }
      return value;
    }
  });
}

// Configure NProgress
NProgress.configure({ 
  showSpinner: false,
  minimum: 0.1,
  trickleSpeed: 200,
  easing: 'ease',
  speed: 500
});

// Initialize app
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Register service worker
registerServiceWorker();