import { getAuth, getIdToken } from 'firebase/auth';
import { AuditService } from '../audit/audit.service';
import { toast } from 'react-hot-toast';

export class SessionService {
  static readonly SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  private static readonly ACTIVITY_KEY = 'last_activity';
  private static readonly AUTH_TOKEN_KEY = 'auth_token';
  private static readonly SESSION_ID_KEY = 'session_id';
  private static readonly SESSION_START_KEY = 'session_start';
  private static readonly TOKEN_EXPIRY_KEY = 'token_expiry';

  static async initSession(token: string): Promise<void> {
    try {
      const sessionId = crypto.randomUUID();
      const timestamp = Date.now();
      const expiryTime = this.getTokenExpiryTime(token);
      
      localStorage.setItem(this.SESSION_ID_KEY, sessionId);
      localStorage.setItem(this.SESSION_START_KEY, timestamp.toString());
      localStorage.setItem(this.AUTH_TOKEN_KEY, token);
      localStorage.setItem(this.TOKEN_EXPIRY_KEY, expiryTime.toString());
      this.updateLastActivity();

      await AuditService.logEvent('session', 'initialized', {
        sessionId,
        timestamp,
        tokenExpiry: expiryTime
      });
    } catch (error) {
      console.error('Session initialization error:', error);
      await AuditService.logEvent('session', 'init_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  }

  static updateLastActivity(): void {
    localStorage.setItem(this.ACTIVITY_KEY, Date.now().toString());
  }

  private static getTokenExpiryTime(token: string): number {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp * 1000; // Convert to milliseconds
    } catch (error) {
      console.error('Token parsing error:', error);
      return Date.now() + (60 * 60 * 1000); // Default to 1 hour
    }
  }

  static isSessionValid(): boolean {
    const lastActivity = localStorage.getItem(this.ACTIVITY_KEY);
    const sessionId = localStorage.getItem(this.SESSION_ID_KEY);
    const token = localStorage.getItem(this.AUTH_TOKEN_KEY);
    const tokenExpiry = localStorage.getItem(this.TOKEN_EXPIRY_KEY);
    
    if (!lastActivity || !sessionId || !token || !tokenExpiry) {
      return false;
    }

    const now = Date.now();
    const timeSinceActivity = now - parseInt(lastActivity);
    const timeUntilExpiry = parseInt(tokenExpiry) - now;
    
    return timeSinceActivity < this.SESSION_TIMEOUT && timeUntilExpiry > 0;
  }

  static getSessionInfo(): { 
    sessionId: string | null;
    lastActivity: number | null;
    sessionStart: number | null;
    tokenExpiry: number | null;
    isValid: boolean;
  } {
    return {
      sessionId: localStorage.getItem(this.SESSION_ID_KEY),
      lastActivity: parseInt(localStorage.getItem(this.ACTIVITY_KEY) || '0') || null,
      sessionStart: parseInt(localStorage.getItem(this.SESSION_START_KEY) || '0') || null,
      tokenExpiry: parseInt(localStorage.getItem(this.TOKEN_EXPIRY_KEY) || '0') || null,
      isValid: this.isSessionValid()
    };
  }

  static async clearSession(): Promise<void> {
    try {
      const sessionInfo = this.getSessionInfo();
      await AuditService.logEvent('session', 'cleared', {
        sessionInfo
      });

      localStorage.removeItem(this.ACTIVITY_KEY);
      localStorage.removeItem(this.AUTH_TOKEN_KEY);
      localStorage.removeItem(this.SESSION_ID_KEY);
      localStorage.removeItem(this.SESSION_START_KEY);
      localStorage.removeItem(this.TOKEN_EXPIRY_KEY);
    } catch (error) {
      console.error('Session clear error:', error);
      await AuditService.logEvent('session', 'clear_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  }
}