// src/components/auth/PrivateRoute.tsx

import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useSessionMonitor } from '../../hooks/useSessionMonitor';
import { SessionService } from '../../services/auth/session.service';
import { LoadingSpinner } from '../ui/LoadingSpinner';
import NProgress from 'nprogress';

export const PrivateRoute: React.FC = () => {
  const { user, isLoading } = useAuth();
  const location = useLocation();
  useSessionMonitor(); // Add session monitoring

  useEffect(() => {
    if (isLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }

    // Log route access attempts
    console.log('[PrivateRoute] Access attempt:', {
      path: location.pathname,
      isAuthenticated: !!user,
      isLoading,
      sessionValid: SessionService.isSessionValid()
    });
  }, [isLoading, location.pathname, user]);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!user || !SessionService.isSessionValid()) {
    console.log('[PrivateRoute] Unauthorized access, redirecting to login');
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

// public/service-worker.js

const VERSION = '1.1.0';
const CACHE_NAME = `autoboost-cache-${VERSION}`;

// Assets to cache
const CACHED_ASSETS = [
  '/',
  '/index.html',
  '/manifest.json',
  '/assets/index.css',
  '/assets/vendor.js',
  '/assets/index.js'
];

// API endpoints that should never be cached
const NO_CACHE_ENDPOINTS = [
  '/api/',
  '/wp-json/',
  'firestore.googleapis.com',
  'identitytoolkit.googleapis.com',
  'securetoken.googleapis.com'
];

// Install event
self.addEventListener('install', (event) => {
  console.log('[ServiceWorker] Installing version:', VERSION);
  
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      console.log('[ServiceWorker] Caching app shell');
      return cache.addAll(CACHED_ASSETS);
    })
  );
});

// Activate event
self.addEventListener('activate', (event) => {
  console.log('[ServiceWorker] Activating version:', VERSION);
  
  event.waitUntil(
    Promise.all([
      // Clean old caches
      caches.keys().then((cacheNames) => {
        return Promise.all(
          cacheNames
            .filter((name) => name.startsWith('autoboost-') && name !== CACHE_NAME)
            .map((name) => {
              console.log('[ServiceWorker] Deleting old cache:', name);
              return caches.delete(name);
            })
        );
      }),
      // Claim clients
      self.clients.claim()
    ])
  );
});

// Fetch event
self.addEventListener('fetch', (event) => {
  // Skip non-GET requests
  if (event.request.method !== 'GET') return;

  // Skip API and authentication requests
  if (NO_CACHE_ENDPOINTS.some(endpoint => event.request.url.includes(endpoint))) {
    return;
  }

  event.respondWith(
    caches.match(event.request).then((response) => {
      if (response) {
        // Return cached response
        console.log('[ServiceWorker] Serving from cache:', event.request.url);
        return response;
      }

      return fetch(event.request).then((networkResponse) => {
        // Check if valid response
        if (!networkResponse || networkResponse.status !== 200 || networkResponse.type !== 'basic') {
          return networkResponse;
        }

        // Clone the response
        const responseToCache = networkResponse.clone();

        // Cache the response
        caches.open(CACHE_NAME).then((cache) => {
          console.log('[ServiceWorker] Caching new resource:', event.request.url);
          cache.put(event.request, responseToCache);
        });

        return networkResponse;
      }).catch((error) => {
        console.error('[ServiceWorker] Fetch failed:', error);
        
        // Return index.html for navigation requests when offline
        if (event.request.mode === 'navigate') {
          console.log('[ServiceWorker] Returning cached homepage');
          return caches.match('/');
        }
        return null;
      });
    })
  );
});

// Handle messages
self.addEventListener('message', (event) => {
  if (event.data === 'skipWaiting') {
    console.log('[ServiceWorker] Skip waiting');
    self.skipWaiting();
  }
});

// Handle errors
self.addEventListener('error', (event) => {
  console.error('[ServiceWorker] Error:', event.error);
});

// Handle unhandled rejections
self.addEventListener('unhandledrejection', (event) => {
  console.error('[ServiceWorker] Unhandled rejection:', event.reason);
});
