import { ObfuscationService } from '../obfuscation.service';
import { AuditService } from '../../audit/audit.service';

export class IntegrityChecker {
  private static readonly CHECKSUM_KEY = 'app_checksums';
  private static obfuscationService = ObfuscationService.getInstance();

  static async verifyApplicationIntegrity(): Promise<boolean> {
    try {
      const storedChecksums = await this.getStoredChecksums();
      if (!storedChecksums) return false;

      const currentChecksums = await this.calculateCurrentChecksums();
      const isValid = this.compareChecksums(storedChecksums, currentChecksums);

      if (!isValid) {
        await AuditService.logEvent('security', 'integrity_check_failed', {
          timestamp: Date.now()
        });
      }

      return isValid;
    } catch (error) {
      await AuditService.logEvent('security', 'integrity_check_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      return false;
    }
  }

  private static async getStoredChecksums(): Promise<Record<string, string> | null> {
    try {
      const stored = localStorage.getItem(this.CHECKSUM_KEY);
      if (!stored) return null;

      const deobfuscated = this.obfuscationService.deobfuscateString(stored);
      return JSON.parse(deobfuscated);
    } catch {
      return null;
    }
  }

  private static async calculateCurrentChecksums(): Promise<Record<string, string>> {
    const checksums: Record<string, string> = {};
    const criticalPaths = [
      '/assets/index.js',
      '/assets/vendor.js',
      '/assets/main.js'
    ];

    for (const path of criticalPaths) {
      try {
        const response = await fetch(path);
        if (!response.ok) continue;

        const content = await response.text();
        checksums[path] = await this.calculateChecksum(content);
      } catch (error) {
        console.error(`Failed to calculate checksum for ${path}:`, error);
      }
    }

    return checksums;
  }

  private static async calculateChecksum(content: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(content);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  private static compareChecksums(
    stored: Record<string, string>,
    current: Record<string, string>
  ): boolean {
    return Object.entries(stored).every(([path, hash]) => current[path] === hash);
  }
}