export interface AuditEvent {
  type: string;
  action: string;
  userId?: string;
  details?: Record<string, any>;
  timestamp: number;
  ip?: string;
  userAgent: string;
}

export class AuditService {
  private static readonly AUDIT_LOG_KEY = 'audit_logs';
  private static readonly MAX_LOGS = 1000;

  static async logEvent(type: string, action: string, details?: Record<string, any>): Promise<void> {
    try {
      const event: AuditEvent = {
        type,
        action,
        userId: await this.getCurrentUserId(),
        details,
        timestamp: Date.now(),
        userAgent: navigator.userAgent
      };

      // Get existing logs
      const logs = this.getLogs();
      
      // Add new log and trim if necessary
      logs.unshift(event);
      if (logs.length > this.MAX_LOGS) {
        logs.length = this.MAX_LOGS;
      }

      // Save logs
      localStorage.setItem(this.AUDIT_LOG_KEY, JSON.stringify(logs));

      // If in production, send to server
      if (import.meta.env.PROD) {
        await this.sendToServer(event);
      }
    } catch (error) {
      console.error('Failed to log audit event:', error);
    }
  }

  static getLogs(): AuditEvent[] {
    try {
      const logs = localStorage.getItem(this.AUDIT_LOG_KEY);
      return logs ? JSON.parse(logs) : [];
    } catch {
      return [];
    }
  }

  static clearLogs(): void {
    localStorage.removeItem(this.AUDIT_LOG_KEY);
  }

  private static async getCurrentUserId(): Promise<string | undefined> {
    try {
      const authService = (await import('../auth/auth.service')).AuthService.getInstance();
      const user = await authService.getCurrentUser();
      return user?.id;
    } catch {
      return undefined;
    }
  }

  private static async sendToServer(event: AuditEvent): Promise<void> {
    const apiInterceptor = (await import('../api/api.interceptor')).APIInterceptor;
    await apiInterceptor.fetch('/audit-logs', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(event)
    });
  }
}