import { doc, getDoc, setDoc, collection, deleteDoc } from 'firebase/firestore';
import { db } from './firebase.init';
import { SecureStorageService } from '../security/secure-storage.service';
import { AuditService } from '../audit/audit.service';
import type { SettingsState } from '../settings.service';
import { getAuth } from 'firebase/auth';

export class FirebaseSettingsService {
  private static instance: FirebaseSettingsService;
  private secureStorage: SecureStorageService;
  private readonly SETTINGS_COLLECTION = 'settings';
  private readonly LOCAL_SETTINGS_KEY = 'autoboost_settings';

  private constructor() {
    this.secureStorage = SecureStorageService.getInstance();
  }

  static getInstance(): FirebaseSettingsService {
    if (!FirebaseSettingsService.instance) {
      FirebaseSettingsService.instance = new FirebaseSettingsService();
    }
    return FirebaseSettingsService.instance;
  }

  async getSettings(userId: string | null): Promise<SettingsState | null> {
    try {
      // If no userId, return null
      if (!userId) {
        this.clearLocalSettings();
        return null;
      }

      // Try Firestore
      const settingsRef = doc(collection(db, this.SETTINGS_COLLECTION), userId);
      const settingsDoc = await getDoc(settingsRef);

      if (settingsDoc.exists()) {
        const settings = settingsDoc.data() as SettingsState;
        
        // If we have settings but no explicit LLM provider, determine it from available configs
        if (settings && !settings.llmProvider) {
          if (settings.openai?.apiKey) {
            settings.llmProvider = 'openai';
          } else if (settings.deepseek?.apiKey) {
            settings.llmProvider = 'deepseek';
          }
        }
        
        // Cache in local storage
        localStorage.setItem(this.LOCAL_SETTINGS_KEY, JSON.stringify(settings));
        
        // Also update the provider in localStorage for compatibility
        if (settings.llmProvider) {
          localStorage.setItem('llm_provider', settings.llmProvider);
        }
        
        return settings;
      }

      // No settings found, ensure local storage is cleared
      this.clearLocalSettings();
      return null;
    } catch (error) {
      console.warn('Failed to get settings:', error);
      this.clearLocalSettings();
      return null;
    }
  }

  private clearLocalSettings(): void {
    // Clear all settings-related items
    localStorage.removeItem(this.LOCAL_SETTINGS_KEY);
    localStorage.removeItem('llm_provider');
    localStorage.removeItem('llm_config');
    localStorage.removeItem('deepseek_config');
    localStorage.removeItem('api_usage');
    localStorage.removeItem('template_overrides');
    localStorage.removeItem('custom_templates');
  }

  async saveSettings(userId: string, settings: SettingsState): Promise<void> {
    try {
      // Get current settings first
      const currentSettings = await this.getSettings(userId) || {};
      
      // Determine the LLM provider if not explicitly set
      if (!settings.llmProvider) {
        // If OpenAI settings are being updated, set provider to OpenAI
        if (settings.openai?.apiKey) {
          settings.llmProvider = 'openai';
        }
        // If DeepSeek settings are being updated, set provider to DeepSeek
        else if (settings.deepseek?.apiKey) {
          settings.llmProvider = 'deepseek';
        }
        // Otherwise keep any existing provider preference
        else if (currentSettings.llmProvider) {
          settings.llmProvider = currentSettings.llmProvider;
        }
      }
      
      // Merge with current settings
      const mergedSettings = {
        ...currentSettings,
        ...settings,
        // Ensure the provider choice is preserved
        llmProvider: settings.llmProvider || currentSettings.llmProvider
      };
      
      // Clean up settings object by removing undefined values
      const cleanSettings = JSON.parse(JSON.stringify(mergedSettings));
      
      // Remove any undefined values recursively
      const removeUndefined = (obj: any) => {
        Object.keys(obj).forEach(key => {
          if (obj[key] === undefined) {
            delete obj[key];
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            removeUndefined(obj[key]);
            // Remove empty objects
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        });
        return obj;
      };

      removeUndefined(cleanSettings);

      // Validate settings before saving
      if (!this.validateSettings(cleanSettings)) {
        throw new Error('Invalid settings format');
      }

      // Save to local storage first
      localStorage.setItem(this.LOCAL_SETTINGS_KEY, JSON.stringify(cleanSettings));

      // Save provider configs and selection to local storage
      if (cleanSettings.llmProvider) {
        localStorage.setItem('llm_provider', cleanSettings.llmProvider);
      }
      if (cleanSettings.openai?.apiKey) {
        localStorage.setItem('llm_config', JSON.stringify(cleanSettings.openai));
      }
      if (cleanSettings.deepseek?.apiKey) {
        localStorage.setItem('deepseek_config', JSON.stringify(cleanSettings.deepseek));
      }

      // Then try to save to Firestore
      try {
        const settingsRef = doc(collection(db, this.SETTINGS_COLLECTION), userId);
        const settingsData = {
          ...cleanSettings,
          userId,
          updatedAt: new Date().toISOString()
        };
        
        await setDoc(settingsRef, settingsData);
        
        await AuditService.logEvent('settings', 'settings_saved_to_firestore', {
          userId,
          provider: cleanSettings.llmProvider || 'not-set'
        });
      } catch (error) {
        console.warn('Failed to save to Firestore, using local storage only:', error);
        await AuditService.logEvent('settings', 'firestore_save_failed', {
          error: error instanceof Error ? error.message : 'Unknown error'
        });
      }

      await AuditService.logEvent('settings', 'settings_saved', {
        userId,
        provider: cleanSettings.llmProvider || 'not-set'
      });
    } catch (error) {
      console.error('Failed to save settings:', error);
      this.clearLocalSettings();
      throw error;
    }
  }

  private validateSettings(settings: SettingsState): boolean {
    if (!settings || typeof settings !== 'object') return false;
    
    // Validate OpenAI settings if present
    if (settings.openai) {
      if (!settings.openai.apiKey || typeof settings.openai.apiKey !== 'string') return false;
      if (settings.openai.model && !['gpt-4', 'gpt-3.5-turbo', 'gpt-4-mini'].includes(settings.openai.model)) return false;
    }
    
    // Validate DeepSeek settings if present
    if (settings.deepseek) {
      if (!settings.deepseek.apiKey || typeof settings.deepseek.apiKey !== 'string') return false;
      if (settings.deepseek.monthlyBudget && typeof settings.deepseek.monthlyBudget !== 'number') return false;
    }
    
    // Validate provider if present
    if (settings.llmProvider && !['openai', 'deepseek'].includes(settings.llmProvider)) {
      return false;
    }
    
    return true;
  }

  async clearSettings(userId?: string): Promise<void> {
    try {
      // Clear local storage first
      this.clearLocalSettings();

      // Try to clear from Firestore if user is authenticated
      let userIdToUse = userId;
      
      if (!userIdToUse) {
        const auth = getAuth();
        userIdToUse = auth.currentUser?.uid || null;
      }
      
      if (userIdToUse) {
        try {
          const settingsRef = doc(collection(db, this.SETTINGS_COLLECTION), userIdToUse);
          await deleteDoc(settingsRef);
          
          await AuditService.logEvent('settings', 'firestore_settings_cleared', {
            userId: userIdToUse
          });
        } catch (error) {
          // Log but don't throw - local storage clear is sufficient
          console.warn('Failed to clear Firestore settings:', error);
        }
      }

      await AuditService.logEvent('settings', 'settings_cleared');
    } catch (error) {
      console.warn('Failed to clear settings:', error);
      // Don't throw - clearing local storage is sufficient
    }
  }
}
