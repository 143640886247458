import React, { createContext, useContext, useState, useEffect } from 'react';
import { IntegrityChecker } from './crypto/integrity-checker';
import { AuditService } from '../audit/audit.service';

interface SecurityState {
  isMonitoring: boolean;
  integrityStatus: boolean;
  securityEvents: Array<{
    type: 'warning' | 'critical' | 'info';
    message: string;
    timestamp: number;
  }>;
}

interface SecurityContextType extends SecurityState {
  updateSecuritySettings: (settings: Partial<SecurityState>) => void;
  runSecurityCheck: () => Promise<void>;
}

const SecurityContext = createContext<SecurityContextType | undefined>(undefined);

export const SecurityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<SecurityState>({
    isMonitoring: false,
    integrityStatus: true,
    securityEvents: []
  });

  const updateSecuritySettings = (settings: Partial<SecurityState>) => {
    setState(prev => ({ ...prev, ...settings }));
  };

  const runSecurityCheck = async () => {
    try {
      const isValid = await IntegrityChecker.verifyApplicationIntegrity();
      
      if (!isValid) {
        setState(prev => ({
          ...prev,
          integrityStatus: false,
          securityEvents: [
            {
              type: 'critical',
              message: 'Application integrity check failed',
              timestamp: Date.now()
            },
            ...prev.securityEvents
          ]
        }));

        await AuditService.logEvent('security', 'integrity_check_failed', {
          timestamp: Date.now()
        });
      } else {
        setState(prev => ({
          ...prev,
          integrityStatus: true,
          isMonitoring: true
        }));
      }
    } catch (error) {
      setState(prev => ({
        ...prev,
        integrityStatus: false,
        securityEvents: [
          {
            type: 'critical',
            message: error instanceof Error ? error.message : 'Security check failed',
            timestamp: Date.now()
          },
          ...prev.securityEvents
        ]
      }));
    }
  };

  useEffect(() => {
    runSecurityCheck();
    
    const interval = setInterval(runSecurityCheck, 5 * 60 * 1000); // Check every 5 minutes
    
    return () => clearInterval(interval);
  }, []);

  return (
    <SecurityContext.Provider value={{
      ...state,
      updateSecuritySettings,
      runSecurityCheck
    }}>
      {children}
    </SecurityContext.Provider>
  );
};

export const useSecurity = () => {
  const context = useContext(SecurityContext);
  if (context === undefined) {
    throw new Error('useSecurity must be used within a SecurityProvider');
  }
  return context;
};