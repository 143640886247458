export function registerServiceWorker() {
  // Skip service worker registration in development, StackBlitz, or WebContainer
  if (import.meta.env.DEV || 
      window.location.hostname.includes('webcontainer.io') ||
      window.location.hostname.includes('stackblitz.io')) {
    console.log('Skipping service worker registration in development/StackBlitz environment');
    return;
  }

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        // Check if we're in a supported environment
        if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
          // Unregister any existing service workers first
          const registrations = await navigator.serviceWorker.getRegistrations();
          await Promise.all(registrations.map(r => r.unregister()));

          // Only register in production
          if (import.meta.env.PROD) {
            const registration = await navigator.serviceWorker.register('/service-worker.js', {
              scope: '/',
              type: 'module',
              updateViaCache: 'none'
            });

            console.log('ServiceWorker registration successful:', registration);

            // Handle updates
            registration.addEventListener('updatefound', () => {
              const newWorker = registration.installing;
              if (!newWorker) return;

              newWorker.addEventListener('statechange', () => {
                if (newWorker.state === 'activated') {
                  // Only reload if there was a previous controller
                  if (navigator.serviceWorker.controller) {
                    window.location.reload();
                  }
                }
              });
            });

            // Prevent multiple refreshes
            let refreshing = false;
            navigator.serviceWorker.addEventListener('controllerchange', () => {
              if (!refreshing) {
                refreshing = true;
                window.location.reload();
              }
            });
          }
        } else {
          console.log('Service Worker registration skipped - unsupported environment');
        }
      } catch (error) {
        console.warn('ServiceWorker registration failed:', error);
      }
    });
  }
}