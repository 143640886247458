// src/utils/toast-manager.ts

import { toast, ToastOptions } from 'react-hot-toast';

// Keep track of active toast IDs
const activeToasts = new Set<string>();

export const toastManager = {
  success: (message: string, options?: ToastOptions) => {
    return toast.success(message, options);
  },
  
  error: (message: string, options?: ToastOptions) => {
    // Check if this error message is already showing
    if (activeToasts.has(message)) {
      return;
    }
    
    const id = toast.error(message, {
      ...options,
      id: message, // Use message as ID to prevent duplicates
      onClose: () => {
        activeToasts.delete(message);
      }
    });
    
    activeToasts.add(message);
    return id;
  },
  
  dismiss: (toastId?: string) => {
    if (toastId) {
      activeToasts.delete(toastId);
    }
    toast.dismiss(toastId);
  }
};
