import React from 'react';
import { Hero } from '../components/home/Hero';
import { Features } from '../components/home/Features';
import { Pricing } from '../components/home/Pricing';
import { Testimonials } from '../components/home/Testimonials';
import { FAQ } from '../components/home/FAQ';
import { CTASection } from '../components/home/CTASection';

export const Home = () => {
  return (
    <div className="bg-white">
      <Hero />
      <Features />
      <Testimonials />
      <Pricing />
      <FAQ />
      <CTASection />
    </div>
  );
};