import { StringObfuscator } from './string-obfuscator';

export class FunctionObfuscator {
  private stringObfuscator: StringObfuscator;

  constructor(key: string) {
    this.stringObfuscator = new StringObfuscator(key);
  }

  obfuscate(fn: Function): string {
    return this.stringObfuscator.obfuscate(fn.toString());
  }

  deobfuscate(obfuscatedFn: string): Function {
    const fnString = this.stringObfuscator.deobfuscate(obfuscatedFn);
    return new Function(`return ${fnString}`)();
  }
}