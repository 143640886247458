import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, RefreshCw, Home } from 'lucide-react';

interface ErrorPageProps {
  title?: string;
  message?: string;
  showRefresh?: boolean;
  showBackHome?: boolean;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  title = 'Something went wrong',
  message = 'An unexpected error occurred. Please try refreshing the page.',
  showRefresh = true,
  showBackHome = true
}) => {
  const navigate = useNavigate();

  const handleRefresh = () => {
    // Store current location to restore after refresh
    sessionStorage.setItem('redirectAfterRefresh', window.location.pathname);
    window.location.reload();
  };

  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 text-center">
        <div>
          <AlertTriangle className="mx-auto h-12 w-12 text-yellow-500" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {title}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {message}
          </p>
        </div>

        <div className="flex justify-center space-x-4">
          {showRefresh && (
            <button
              onClick={handleRefresh}
              className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
            >
              <RefreshCw className="h-4 w-4" />
              Refresh Page
            </button>
          )}
          {showBackHome && (
            <button
              onClick={handleBackHome}
              className="inline-flex items-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-50"
            >
              <Home className="h-4 w-4" />
              Back Home
            </button>
          )}
        </div>
      </div>
    </div>
  );
};