import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  getAuth, 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut as firebaseSignOut, 
  sendPasswordResetEmail, 
  updatePassword, 
  getIdToken,
  User as FirebaseUser 
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase/firebase.init';
import { SettingsService } from '../services/settings.service';
import { SessionService } from '../services/auth/session.service';
import { AuditService } from '../services/audit/audit.service';
import { extractErrorCode, getAuthErrorMessage } from '../utils/auth-error-messages';
import { toastManager } from '../utils/toast-manager';
import NProgress from 'nprogress';

interface User {
  uid: string;
  email: string | null;
  displayName: string | null;
}

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean; // Added for easier checks
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const settingsService = new SettingsService();

  const setupSession = async (firebaseUser: FirebaseUser) => {
    try {
      const token = await getIdToken(firebaseUser);
      await SessionService.initSession(token);
    } catch (error) {
      console.error('[Auth] Session setup error:', error);
      throw error;
    }
  };

  const handleSignOut = async () => {
    try {
      NProgress.start();
      
      // Clear session first
      await SessionService.clearSession();

      // Clear settings from local storage
      localStorage.removeItem('autoboost_settings');

      // Sign out from Firebase
      await firebaseSignOut(auth);
      
      await AuditService.logEvent('auth', 'sign_out_success');
      toastManager.success('Signed out successfully');
    } catch (error) {
      console.warn('Sign out error:', error);
      // Don't throw - just log the error
      await AuditService.logEvent('auth', 'sign_out_error', {
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    } finally {
      NProgress.done();
    }
  };

  const handleSignIn = async (email: string, password: string) => {
    try {
      NProgress.start();
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      
      // Set up session
      await setupSession(firebaseUser);

      await AuditService.logEvent('auth', 'sign_in_success', {
        userId: firebaseUser.uid
      });

      toastManager.success('Signed in successfully');
    } catch (error) {
      console.error('[Auth] Sign in error:', error);
      
      // Get a user-friendly error message
      const errorCode = extractErrorCode(error);
      const userFriendlyMessage = getAuthErrorMessage(errorCode);
      
      await AuditService.logEvent('auth', 'sign_in_error', {
        errorCode,
        errorMessage: error instanceof Error ? error.message : 'Unknown error'
      });
      
      // No toast here to avoid duplicate error notifications
      
      // Create a new error with the user-friendly message
      const enhancedError = new Error(userFriendlyMessage);
      enhancedError.name = 'AuthError';
      if (error instanceof Error) {
        enhancedError.stack = error.stack;
        // @ts-ignore
        enhancedError.code = error.code;
      }
      
      throw enhancedError;
    } finally {
      NProgress.done();
    }
  };

  const clearStorageData = () => {
    try {
      // Clear all auth-related data
      const keysToRemove = [
        'autoboost_settings',
        'llm_provider',
        'llm_config',
        'deepseek_config',
        'api_usage',
        'template_overrides',
        'custom_templates',
        'wp_connection_status',
        'firebase:host:boltautoboost.firebaseapp.com'
      ];
      
      // Clear specific keys
      keysToRemove.forEach(key => localStorage.removeItem(key));
      
      // Clear all Firebase-related keys
      Object.keys(localStorage)
        .filter(key => key.startsWith('firebase:'))
        .forEach(key => localStorage.removeItem(key));
      
      // Clear session storage
      sessionStorage.clear();
      
      console.log('[Auth] Storage data cleared successfully');
    } catch (error) {
      console.error('[Auth] Error clearing storage:', error);
    }
  };

  const handleSignUp = async (email: string, password: string, name: string) => {
    try {
      NProgress.start();
      setIsLoading(true);
      let firebaseUser = null;
      
      // Clear any existing settings first
      clearStorageData();
      
      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        firebaseUser = result.user;
      } catch (error) {
        const errorCode = extractErrorCode(error);
        const userFriendlyMessage = getAuthErrorMessage(errorCode);
        
        await AuditService.logEvent('auth', 'sign_up_error', {
          code: errorCode,
          message: error instanceof Error ? error.message : 'Unknown error'
        });
        
        throw new Error(userFriendlyMessage);
      }

      if (!firebaseUser) {
        throw new Error('Failed to create account. Please try again.');
      }

      // Create user document with retry
      let retryCount = 0;
      const maxRetries = 3;
      
      while (retryCount < maxRetries) {
        try {
          await setDoc(doc(db, 'users', firebaseUser.uid), {
            email,
            displayName: name,
            createdAt: new Date().toISOString()
          });
          break;
        } catch (error) {
          retryCount++;
          if (retryCount === maxRetries) {
            // Delete the auth user if we can't create their profile
            await firebaseUser.delete();
            throw new Error('Failed to create user profile. Please try again later.');
          }
          await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        }
      }
      
      // Set up session
      await setupSession(firebaseUser);

      await AuditService.logEvent('auth', 'sign_up_success', {
        userId: firebaseUser.uid
      });

      toastManager.success('Account created successfully');
    } catch (error) {
      console.error('[Auth] Sign up error:', error);
      
      // Ensure proper error object for logging
      const errorObj = error instanceof Error ? error : new Error('Unknown error');
      
      await AuditService.logEvent('auth', 'sign_up_failed', {
        error: errorObj.message,
        stack: errorObj.stack
      });
      
      // No toast to avoid duplicates
      throw errorObj;
    } finally {
      setIsLoading(false);
      NProgress.done();
    }
  };

  const handleResetPassword = async (email: string) => {
    try {
      NProgress.start();
      await sendPasswordResetEmail(auth, email);
      
      await AuditService.logEvent('auth', 'password_reset_requested', {
        email
      });
      
      toastManager.success('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('[Auth] Password reset error:', error);
      
      const errorCode = extractErrorCode(error);
      const userFriendlyMessage = getAuthErrorMessage(errorCode);
      
      await AuditService.logEvent('auth', 'password_reset_failed', {
        errorCode,
        errorMessage: error instanceof Error ? error.message : 'Unknown error'
      });
      
      // No toast to avoid duplicate error messages
      throw new Error(userFriendlyMessage);
    } finally {
      NProgress.done();
    }
  };

  const handleChangePassword = async (currentPassword: string, newPassword: string) => {
    if (!auth.currentUser?.email) {
      throw new Error('No user is currently signed in');
    }

    try {
      NProgress.start();
      // Re-authenticate user
      await signInWithEmailAndPassword(auth, auth.currentUser.email, currentPassword);
      
      // Update password
      await updatePassword(auth.currentUser, newPassword);
      
      await AuditService.logEvent('auth', 'password_changed', {
        userId: auth.currentUser.uid
      });

      toastManager.success('Password changed successfully');
    } catch (error) {
      console.error('[Auth] Change password error:', error);
      
      const errorCode = extractErrorCode(error);
      const userFriendlyMessage = getAuthErrorMessage(errorCode);
      
      await AuditService.logEvent('auth', 'password_change_failed', {
        errorCode,
        errorMessage: error instanceof Error ? error.message : 'Unknown error'
      });
      
      // Don't show toast here to avoid duplicate errors
      throw new Error(userFriendlyMessage);
    } finally {
      NProgress.done();
    }
  };

  useEffect(() => {
    console.log('[Auth] Setting up auth listener');
    
    // Use a flag to prevent multiple auth processing
    let isProcessing = false;
    
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      // Skip if already processing
      if (isProcessing) return;
      isProcessing = true;
      
      try {
        if (firebaseUser) {
          console.log('[Auth] User authenticated:', firebaseUser.uid);
          
          try {
            // Set up fresh session
            await setupSession(firebaseUser);

            // Get additional user data
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            const userData = userDoc.data();
          
            const userObj = {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              displayName: userData?.displayName || firebaseUser.displayName
            };
          
            setUser(userObj);

            // Load user settings
            const settings = await settingsService.getSettings();
            if (settings) {
              await settingsService.saveSettings(settings);
            }
          } catch (error) {
            console.error('[Auth] User data/settings error:', error);
            // Don't sign out just because settings failed
            setUser(null);
          }
        } else {
          console.log('[Auth] No user authenticated');
          setUser(null);
          await SessionService.clearSession();
        }
      } catch (error) {
        console.error('[Auth] Auth state change error:', error);
        setUser(null);
      } finally {
        setIsLoading(false);
        isProcessing = false;
      }
    });

    return () => {
      console.log('[Auth] Cleaning up auth listener');
      isProcessing = false;
      unsubscribe();
    };
  }, [auth]);

  return (
    <AuthContext.Provider value={{
      user,
      isLoading,
      isAuthenticated: !!user,
      signIn: handleSignIn,
      signUp: handleSignUp,
      signOut: handleSignOut,
      resetPassword: handleResetPassword,
      changePassword: handleChangePassword
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
