export class ObfuscationValidator {
  static validateObfuscatedString(value: string): boolean {
    try {
      // Check if string is base64 encoded
      if (!/^[A-Za-z0-9+/=]+$/.test(value)) {
        return false;
      }

      // Check if it contains the expected separator after decoding
      const decoded = atob(value);
      return decoded.includes('|');
    } catch {
      return false;
    }
  }

  static validateObfuscatedObject(obj: any): boolean {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    return Object.values(obj).every(value => {
      if (typeof value === 'string') {
        return this.validateObfuscatedString(value);
      }
      if (typeof value === 'object' && value !== null) {
        return this.validateObfuscatedObject(value);
      }
      return true;
    });
  }
}