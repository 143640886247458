import React from 'react';
import { Check } from 'lucide-react';

export const Pricing = () => {
  const tiers = [
    {
      name: 'Starter',
      id: 'tier-starter',
      price: '29',
      description: 'Perfect for small blogs and personal websites.',
      features: [
        'Up to 50 posts per month',
        'Basic SEO optimization',
        'Content enhancement',
        'Meta description generation',
        'Email support'
      ],
      cta: 'Start free trial'
    },
    {
      name: 'Professional',
      id: 'tier-professional',
      price: '79',
      description: 'Ideal for growing businesses and content teams.',
      features: [
        'Up to 200 posts per month',
        'Advanced SEO optimization',
        'Priority content enhancement',
        'Custom templates',
        'Priority support',
        'Analytics dashboard',
        'Team collaboration'
      ],
      cta: 'Start free trial',
      featured: true
    },
    {
      name: 'Enterprise',
      id: 'tier-enterprise',
      price: '299',
      description: 'For large organizations with complex needs.',
      features: [
        'Unlimited posts',
        'Enterprise-grade SEO',
        'Custom AI models',
        'API access',
        'Dedicated account manager',
        'Custom integrations',
        'SLA guarantee',
        '24/7 phone support'
      ],
      cta: 'Contact sales'
    }
  ];

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Choose the right plan for&nbsp;you
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Flexible plans to meet your content enhancement needs. All plans include a 14-day free trial.
        </p>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={`flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 ${
                tier.featured ? 'lg:z-10 lg:rounded-b-none' : ''
              } ${tierIdx === 0 ? 'lg:rounded-r-none' : ''} ${
                tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : ''
              }`}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3 className="text-lg font-semibold leading-8 text-gray-900">{tier.name}</h3>
                  {tier.featured && (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      Most popular
                    </p>
                  )}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">${tier.price}</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
                </p>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <Check className="h-6 w-5 flex-none text-indigo-600" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <a
                href={tier.featured ? '/signup' : tier.cta === 'Contact sales' ? '/contact' : '/signup'}
                className={`mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  tier.featured
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600'
                    : 'bg-white text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300'
                }`}
              >
                {tier.cta}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};