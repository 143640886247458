import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Home } from '../pages/Home';
import { Layout } from '../components/Layout';
import { PublicLayout } from '../components/layout/PublicLayout';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { SignInForm } from '../components/auth/SignInForm';
import { SignUpForm } from '../components/auth/SignUpForm';
import { lazy, Suspense } from 'react';
import { ResetPasswordForm } from '../components/auth/ResetPasswordForm';
import { PrivateRoute } from '../components/auth/PrivateRoute';
import { ErrorPage } from '../components/ErrorPage';
import { ErrorBoundary } from '../components/ErrorBoundary';
import NProgress from 'nprogress';

// Lazy load components with proper error boundaries
const Dashboard = lazy(() => import('../components/Dashboard').catch(() => {
  console.error('Failed to load Dashboard component');
  return import('../components/ErrorPage').then(module => ({
    default: () => <module.ErrorPage title="Failed to load Dashboard" showRefresh />
  }));
}));

const Settings = lazy(() => import('../components/settings/Settings').catch(() => {
  console.error('Failed to load Settings component');
  return import('../components/ErrorPage').then(module => ({
    default: () => <module.ErrorPage title="Failed to load Settings" showRefresh />
  }));
}));

const PostContent = lazy(() => import('../components/posts/PostContent').catch(() => {
  console.error('Failed to load PostContent component');
  return import('../components/ErrorPage').then(module => ({
    default: () => <module.ErrorPage title="Failed to load Content" showRefresh />
  }));
}));

const TemplateDashboard = lazy(() => import('../components/templates/TemplateDashboard').catch(() => {
  console.error('Failed to load TemplateDashboard component');
  return import('../components/ErrorPage').then(module => ({
    default: () => <module.ErrorPage title="Failed to load Templates" showRefresh />
  }));
}));

export const AppRoutes: React.FC = () => {
  const location = useLocation();

  // Handle route changes
  useEffect(() => {
    NProgress.start();
    
    const cleanup = () => {
      NProgress.done();
    };

    // Clean up on route change complete
    cleanup();

    return () => {
      cleanup();
    };
  }, [location.pathname]);

  // Check for redirect after refresh
  useEffect(() => {
    const redirectPath = sessionStorage.getItem('redirectAfterRefresh');
    if (redirectPath) {
      sessionStorage.removeItem('redirectAfterRefresh');
      window.history.replaceState(null, '', redirectPath);
    }
  }, []);

  return (
    <ErrorBoundary>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignInForm />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/reset-password" element={<ResetPasswordForm />} />
          <Route path="/login" element={<Navigate to="/signin" replace />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route path="/dashboard" element={
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorBoundary fallback={<ErrorPage title="Failed to load Dashboard" showRefresh />}>
                  <Dashboard />
                </ErrorBoundary>
              </Suspense>
            } />
            <Route path="/content" element={
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorBoundary>
                  <PostContent />
                </ErrorBoundary>
              </Suspense>
            } />
            <Route path="/templates" element={
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorBoundary>
                  <TemplateDashboard />
                </ErrorBoundary>
              </Suspense>
            } />
            <Route path="/settings" element={
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorBoundary>
                  <Settings />
                </ErrorBoundary>
              </Suspense>
            } />
          </Route>
        </Route>

        {/* Error Routes */}
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/404" element={
          <ErrorPage 
            title="Page Not Found"
            message="The page you're looking for doesn't exist or has been moved."
          />
        } />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </ErrorBoundary>
  );
};